<template>
  <div class="tool">
      <div class="search">
          <div style="margin-right: 10px;">
              <span style="font-size: 0.8rem;">时间：</span>
              <el-date-picker v-model="searchForm.begintime" :type="dateType" :format="formateData" value-format='yyyy-MM-dd' placeholder="选择日期"></el-date-picker>
          </div>
          <div style="margin-right: 10px;">
            <span style="font-size: 0.8rem;">车牌号：</span>
            <el-input v-model="searchForm.plateno" class="input-with-select" style="width:200px"></el-input>
          </div>
          <div style="margin-right: 10px;">
              <span style="font-size: 0.8rem;">公司：</span>
              <el-select v-model="searchForm.companyId" style="width:150px">
                <el-option label="全部" :value="0"></el-option>
                <el-option v-for='item in $store.state.CompanyDataList' :key='item.cp_id' :label='item.cp_name' :value='item.cp_id'></el-option>
            </el-select>
          </div>
          <div style="margin-right: 10px;">
            <span style="font-size: 0.8rem;">工地：</span>
            <el-select v-model="searchForm.lt_id" style="width:150px">
              <el-option label="全部" :value="0"></el-option>
              <el-option v-for='item in $store.state.LocationDataList' :key='item.lt_id' :label='item.lt_name' :value='item.lt_id'></el-option>
          </el-select>
          
        </div>
          <el-button style="margin-right: 10px;" icon="el-icon-search" type="primary" @click='Search'>查询</el-button>
          <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="统计汇总" :fetch="createExportData" :before-finish="finishDownload" worksheet="统计汇总">
              <el-button type="primary" class="el-icon-download"> 导出</el-button>
          </JsonExcel>
      </div>
      

      <el-menu style="margin-top: 10px;" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">日报汇总</el-menu-item>
          <el-menu-item index="2">月报汇总</el-menu-item>
          <el-menu-item index="3">年报汇总</el-menu-item>
      </el-menu>
      <el-table :data="DataList" size="mini" ref='orderTable' stripe :highlight-current-row='true' max-height='calc(100vh - 200px)' height='calc(100vh - 200px)'>
          <el-table-column  align="center" label=" " prop="time"></el-table-column>
          <el-table-column  align="center" label="车牌号" prop="plateno"></el-table-column>
          <el-table-column  align="center" label="驾驶员" prop="name"></el-table-column>
          <el-table-column  align="center" label="公司" prop="cp_name"></el-table-column>
          <el-table-column  align="center" label="总计(吨)" prop="total"></el-table-column>
          <el-table-column  align="center" label="异常" prop="error">
            <template slot-scope="scope">
              <el-tag :type="scope.row.error === 0 ? 'success' : 'danger'" disable-transitions>{{scope.row.error===0?'正常':'异常'}}</el-tag>
            </template>
          </el-table-column>
      </el-table>
  </div>
</template>

<script>
import {post,get,FormateDate} from '../../util/util'
import JsonExcel from 'vue-json-excel'

export default{
data:function(){
    return{
      dateType:'date',
      search_time:'',
      formateData:'yyyy-MM-dd',
      activeIndex:"1",

      searchForm:{
          begintime:'',
          plateno:"",
          companyId:0,
          lt_id:0
      },

      FiledName:[],
      DataList:[],

      exportDataStandard:{},
      exportData: [],
      exportFooter:''
    }
},
components:{
  JsonExcel
},
computed:{
},
created(){
  if(this.$store.state.CompanyDataList.length===0){
        this.$store.dispatch('getCompanyDataList')
    }
    if(this.$store.state.LocationDataList.length===0){
        this.$store.dispatch('getLocationDataList',1)
    }
  this.searchForm.begintime=new Date()
  this.getDataList()
},
methods:{
  handleSelect(index){
      let dt="date"
      let st=new Date()
      this.formateData='yyyy-MM-dd'
      if(index==="2"){
          dt="month"
          this.formateData='yyyy-MM'
      }
      if(index==="3"){
          dt="year"
          this.formateData='yyyy'
      }
      this.dateType=dt
      this.searchForm.begintime=st
      this.activeIndex=index
  },
  getDataList(){
    this.searchForm.time_type=parseInt(this.activeIndex)
    get('logsum/GetLogSumDataList', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          console.log(res.list)
          this.DataList = res.list
          this.searchForm.total = res.total
        }
      })
  },
  Search(){
      this.getDataList()
  },

   // 导出
   async createExportData () {
      let arr=JSON.parse(JSON.stringify(this.DataList))
      if(arr.length>0){
          arr.forEach(a=>{
              this.cardDataList.forEach(card=>{
                  var info= a.listCard.filter(item=>item.card_id===card.card_id)
                  a["card_id_"+card.card_id+"_totalInout"]=info[0].inoutTotal
                  a["card_id_"+card.card_id+"_card_charge"]=info[0].card_charge
              })
          })
      }

      this.exportDataStandard={}
      this.exportDataStandard.日期="time"

      this.cardDataList.forEach(element => {
          this.exportDataStandard[element.card_name+'数量']="card_id_"+element.card_id+"_totalInout"
          this.exportDataStandard[element.card_name+'金额']="card_id_"+element.card_id+"_card_charge"
      });

      this.exportDataStandard.免费放行数量="freecount"
      this.exportDataStandard.免费放行金额="freecharge"
      this.exportDataStandard.总车辆数="totalInout"
      this.exportDataStandard.减免金额="discharge"
      this.exportDataStandard.现金收费="money"
      this.exportDataStandard.电子收费="elcmoney"

      this.exportData=arr
      return this.exportData
  },
  finishDownload () {
      this.$message.success('导出数据成功')
      this.exportData=[]
  }
}
}


</script>


<style scoped>
.tool{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
}
.search{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
</style>